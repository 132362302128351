import { useQuery } from '@apollo/client'
import * as Sentry from '@sentry/nextjs'
import { useSession, signOut } from 'next-auth/react'
import { useRouter } from 'next/router'
import { useEffect, useMemo, useState } from 'react'

import { Creator, InternalSubscriptionStatus } from '@snipfeed/graphql/types'

import JoinProModal from './JoinProModal'

import GET_PROFILE from '@/graphql/queries/profile.gql'
import * as gtm from '@/lib/gtm'

const ONE_HOUR = 60 * 60 * 1000
function isUserNew(createdAtDate: Date) {
  return Date.now() - ONE_HOUR < createdAtDate.getTime()
}

interface AuthGuardProps {
  children: React.ReactNode
}

let hasSeenJoinProModal = false
export default function AuthGuard({
  children,
}: AuthGuardProps): JSX.Element | null {
  const [isAuthorized, setIsAuthorized] = useState(false)
  const [joinProModal, setJoinProModal] = useState(false)

  const router = useRouter()
  const { data: session, status: sessionStatus } = useSession()
  const accessToken: string | null = useMemo(
    () => (session?.snipfeedAccessToken as string) ?? null,
    [session]
  )
  const {
    data: { profile: user } = {},
    loading: loadingProfile,
    client,
  } = useQuery<{ profile: Creator }>(GET_PROFILE, {
    skip: sessionStatus !== 'authenticated',
    fetchPolicy: 'cache-first',
  })

  useEffect(() => {
    console.log('Session status inner', sessionStatus)
  }, [sessionStatus])

  useEffect(() => {
    if (loadingProfile || sessionStatus === 'loading') {
      // if no redirect needed, just return
      // if user data not yet there (fetch in progress, logged in or not) then don't do anything yet
      return
    }
    // If redirectTo is set, redirect if the user was not found.
    if (sessionStatus === 'authenticated' && !user?.id) {
      signOut()
        .then(() => client.clearStore())
        .then(() => {
          router.push('/login')
        })
    }

    if (sessionStatus === 'unauthenticated') {
      router.push('/login')
      Sentry.configureScope((scope) => scope.setUser(null))
    }

    if (user?.username) {
      Sentry.setUser({
        id: user.id,
        usename: user.username,
        email: user.email || undefined,
      })
      gtm.identify({
        userId: user.id,
        databaseId: user.databaseId,
        name: user.name,
        username: user.username,
        email: user.email,
        referredBy: user.referredBy,
        createdAt: user.createdAt,
        isPro: user.isUpgraded,
        phone: user.phone,
        categories: user.categories as string[],
        plan: user.activeInternalSubscription?.plan,
        planolyId: user.planolyAccount?.userId,
        socialSetId: user.planolyAccount?.socialSetId,
        stripePriceId: user.activeInternalSubscription?.priceId,
        isOnTrial:
          user.activeInternalSubscription?.status ==
          InternalSubscriptionStatus.Trialing,
      })
    }

    // if all neccessary checks are passed then render the component
    if (sessionStatus === 'authenticated' && !loadingProfile && user?.id) {
      setIsAuthorized(true)
    }
  }, [user, loadingProfile, client, accessToken, sessionStatus, router])

  useEffect(() => {
    if (
      loadingProfile ||
      sessionStatus === 'loading' ||
      !user ||
      user.isUpgraded ||
      hasSeenJoinProModal
    ) {
      return
    }

    if (
      !router.pathname.includes('/settings/billing') &&
      !isUserNew(new Date(user.createdAt))
    ) {
      setJoinProModal(true)
      hasSeenJoinProModal = true
    }
  }, [loadingProfile, router.pathname, sessionStatus, user])

  const onCloseJoinProModal = () => setJoinProModal(false)
  const onFindOutMore = () => {
    router.push('/settings/billing')
    onCloseJoinProModal()
  }

  return isAuthorized ? (
    <>
      {joinProModal && (
        <JoinProModal
          title={{
            text: 'Action required to keep using Snipfeed',
          }}
          actions={{
            primary: {
              text: 'Upgrade now',
              onClick: onFindOutMore,
            },
            secondary: { text: 'No thanks', onClick: onCloseJoinProModal },
          }}
          onClose={onCloseJoinProModal}
          onFindOutMore={onFindOutMore}
        >
          <p className="mb-3">
            Oops! We noticed that your Snipfeed site is still online, even
            though you don&apos;t have an active paid subscription.
          </p>
          <p>
            In order to keep using Snipfeed, you&apos;ll need to upgrade to a
            paid plan.
          </p>
        </JoinProModal>
      )}
      {children}
    </>
  ) : null
}
